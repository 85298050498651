import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import { Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import "./App.css";

// Import the two components
import ImageOCR from "./pages/ImageOCRInput"; // Component for Image OCR
import BarCodeScanner from "./pages/BarCodeScanner"; // Component for Barcode Scanner

const menuItems = [
  {
    key: "Barcode Scanner",
    label: <Link to="/scanner">Barcode Scanner</Link>,
  },
  // { key: "Order Input", label: <Link to="/image-ocr">Order Input</Link> },
];

export default function App() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" }); // Detects screens less than 768px wide
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [menuSelectedKeys, setMenuSelectedKeys] = useState(menuItems[0].key);

  return (
    <Router>
      <div className="App">
        {/* Navigation */}
        {isMobile ? (
          <>
            {/* Mobile Menu */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Button
                icon={<MenuOutlined />}
                onClick={() => setDrawerVisible(true)}
                style={{ margin: "10px" }}
              />
              {menuSelectedKeys}
            </div>
            <Drawer
              title="Navigation"
              placement="left"
              onClose={() => setDrawerVisible(false)}
              visible={drawerVisible}
            >
              <Menu
                selectedKeys={menuSelectedKeys}
                mode="vertical"
                items={menuItems}
                onClick={() => setDrawerVisible(false)}
                onSelect={(item, key, keyPath, selectedKeys, domEvent) =>
                  setMenuSelectedKeys(selectedKeys)
                }
              />
            </Drawer>
          </>
        ) : (
          <Menu
            style={{ width: "80vw" }}
            selectedKeys={menuSelectedKeys}
            mode="horizontal"
            items={menuItems}
            onSelect={(item, key, keyPath, selectedKeys, domEvent) =>
              setMenuSelectedKeys(selectedKeys)
            }
          />
        )}

        {/* Routes */}
        <Routes>
          <Route path="/image-ocr" element={<ImageOCR />} />
          <Route path="/scanner" element={<BarCodeScanner />} />
          <Route path="*" element={<Navigate to="/scanner" />} />
        </Routes>
      </div>
    </Router>
  );
}
