import React, { useEffect, useState } from "react";
import { Modal, Upload, Button, List, Input, Form, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { createWorker } from "tesseract.js";

const { Dragger } = Upload;

const AddNewProductModal = ({ visible, onClose }) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [extractedText, setExtractedText] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Handle Image Upload
  const handleUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedImage(reader.result);
      document.getElementById("uploadedImage").src = reader.result;
    };
    reader.readAsDataURL(file);
    return false; // Prevent auto upload
  };

  useEffect(() => {
    console.log(uploadedImage);
  }, [uploadedImage]);

  // Extract Text using Tesseract.js
  const extractText = async () => {
    if (!uploadedImage) {
      message.error("Please upload an image first!");
      return;
    }
    setLoading(true);
    try {
      const worker = await createWorker(['eng', 'chi_sim']);

      const {
        data: { text },
      } = await worker.recognize(uploadedImage);
      const lines = text.split("\n").filter((line) => line.trim() !== "");
      setExtractedText(lines);
      message.success("Text extracted successfully!");
    } catch (err) {
      message.error("Failed to extract text. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle Form Submission
  const handleFinish = (values) => {
    console.log("Mapped Values:", values);
    message.success("Order information saved!");
    onClose();
  };

  useEffect(() => {
    setUploadedImage(null);
    setExtractedText([]);
  }, [visible]);

  return (
    <Modal
      title="Extract Order Information"
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <img
            id={"uploadedImage"}
            style={{ maxHeight: uploadedImage ? "30vh" : 0 }}
          />

          {uploadedImage ? (
            <></>
          ) : (
            <Dragger
              beforeUpload={handleUpload}
              accept="image/*"
              multiple={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag an image to upload
              </p>
            </Dragger>
          )}
        </div>
        <div>
          <Button
            type="primary"
            onClick={extractText}
            style={{ marginTop: 16 }}
            loading={loading}
            block
          >
            Extract Text
          </Button>
          <List
            style={{ marginTop: 16, maxHeight: 200, overflowY: "scroll" }}
            bordered
            dataSource={extractedText}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
          {extractedText.length > 0 && (
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFinish}
              style={{ marginTop: 16 }}
            >
              <Form.Item
                label="物品"
                name="item"
                rules={[{ required: true, message: "Please select an item!" }]}
              >
                <Input placeholder="Select from the extracted text" />
              </Form.Item>
              <Form.Item
                label="快递公司"
                name="courier"
                rules={[
                  { required: true, message: "Please enter the courier!" },
                ]}
              >
                <Input placeholder="Enter courier company" />
              </Form.Item>
              <Form.Item label="件数" name="quantity" initialValue="1">
                <Input placeholder="Enter quantity" />
              </Form.Item>
              <Form.Item
                label="单号"
                name="trackingNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter the tracking number!",
                  },
                ]}
              >
                <Input placeholder="Enter tracking number" />
              </Form.Item>
              <Form.Item
                label="签收日期"
                name="receivedDate"
                rules={[{ required: true, message: "Please enter the date!" }]}
              >
                <Input placeholder="Enter received date" />
              </Form.Item>
              <Form.Item
                label="估值"
                name="value"
                rules={[{ required: true, message: "Please enter the value!" }]}
              >
                <Input placeholder="Enter the estimated value" />
              </Form.Item>
              <Button type="primary" htmlType="submit" block>
                Save
              </Button>
            </Form>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddNewProductModal;
