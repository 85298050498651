import React, { useState } from "react";
import AddNewProductModal from "../modals/addNewProduct";


export default function ImageOCR() {
  const [modalVisible, setModalVisible] = useState(false);
  
  return (
    <div>
    <div>
      <h1>Image OCR</h1>
      <p onClick={() => setModalVisible(true)}>Show Modal</p>
    </div>
    <AddNewProductModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
}
